const Language = [{
  en: {
    buttonLogin: 'Sign in',
    buttonLogout: 'Sign out',
  },
  es: {
    buttonLogin: "S'identifie",
    buttonLogout: 'Déconnexion',
  },
  de: {
    buttonLogin: "Einloggen",
    buttonLogout: 'Ausloggen',
  },
  ua: {
    buttonLogin: 'Увійти',
    buttonLogout: 'Вийти',
  },
}]
export default Language;