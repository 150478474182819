const Language = [{
    en: {
        pageNot: <>
            Page not found
        </>,
        check: <>
            Check if you entered the correct address.
        </>,
        redirect: <>
        </>,
        button: <>
              <button className="btn btn-outline-primary buttonStyle " >Go to home</button>
        </>
    },
    es: {
        pageNot: <>
            Página no encontrada
        </>,
        check: <>
            Verifique si ingresó la dirección correcta.
        </>,
        redirect: <>
        
        </>,
        button: <>
              <button className="btn btn-outline-primary buttonStyle " >Ir a casa</button>
        </>
    },
    de: {
        pageNot: <>
           Seite nicht gefunden
        </>,
        check: <>
            Überprüfen Sie, ob Sie die richtige Adresse eingegeben haben.
        </>,
        redirect: <>
       
        </>,
        button: <>
              <button className="btn btn-outline-primary buttonStyle " >Zur Startseite</button>
        </>
    },
    ua: {
        pageNot: <>
        Сторінку не знайдено
    </>,
    check: <>
       Перевірте, чи правильно ви ввели адресу.
    </>,
    redirect: <>
     
    </>,
    button: <>
          <button className="btn btn-outline-primary buttonStyle " >На головну</button>
    </>
    },
}]
export default Language;