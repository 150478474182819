import React from "react";

//Styles
import "./BannerImages.css";
//import logoMob from "../../images/logo.png";
import glass_hb from "../../images/glass_hb_90.png";
import marmelad_tarelka from "../../images/marmelad_tarilka.png";
import cup_mishka from "../../images/cup_mishka3.png";
import glass_sakura from "../../images/glass_sakura_90_2.png";
import mishka_tarelka from "../../images/mishka_tarilka.png";
import cup_marmelad from "../../images/cup_marmelad.png";
import hb_tarelka from "../../images/HB_tarelka.jpg";
import glass_mishka from "../../images/glass_mishka_90.png";
import glass_marmelad from "../../images/glass_marmelad_90.png";
import sakura_tarelka from "../../images/sakura_tarilka.png";
import googleplay from "../../images/GooglePlay.png";
import Language from "./BannerLang.js";


export default class BannerImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stateLanguage: { ...props }
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({
            stateLanguage: this.props.stateLanguage
        });

    }
    render() {
        var stateLanguage   = this.props.stateLanguage;
        const [languages] = Language;
        return (
            <main className="main">
                <div className="container">
                    <h5 style={{ textAlign: "center", color: "purple", textTransform: "uppercase", marginTop: '1em' }}>
                        {stateLanguage && languages[stateLanguage].description1}
                    </h5>
                    <div id="carouselExampleIndicators" className="carousel slide main-banner" data-interval="false" style={{ marginTop: '1em' }}>
                        <ol className="carousel-indicators" style={{ marginTop: '50px' }}>
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
                        </ol>
                        <div className="carousel-inner">

                            <div className="carousel-item active">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>
                                    <div className="col-8">
                                        <img className="d-block " src={glass_hb} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                    </div>
                                    <div className="col"> </div>
                                </div>

                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={marmelad_tarelka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={cup_mishka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={glass_sakura} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={mishka_tarelka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={cup_marmelad} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={hb_tarelka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={glass_mishka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={glass_marmelad} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row" style={{ marginBottom: '50px' }}>
                                    <div className="col"> </div>

                                    <div className="col-8">
                                        <img className="d-block " src={sakura_tarelka} alt="https://prom.ua/c667862-koza-style.html?product_group=99301489" />
                                        <div className="carousel-caption">
                                        </div>
                                    </div>

                                    <div className="col"> </div>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" >
                            <span className="carousel-control-prev-icon" aria-hidden="true" color="purple" style={{ backgroundImage: "url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='purple' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E\")" }}></span>
                            <span className="sr-only"  >Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"  >
                            <span className="carousel-control-next-icon" aria-hidden="true" style={{ backgroundImage: "url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='purple' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E\")" }}></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    <div style={{ fontSize: "1.25em", paddingBottom: "0.5em" }}>
                        {stateLanguage && languages[stateLanguage].instructions}
                    </div>
                    <ol className="instruction-list">
                        {stateLanguage && languages[stateLanguage].instructionList}
                    </ol>
                    <div className="row" style={{ marginTop: "1.25em", marginBottom:"1.5em" }}> 
                        <div className="col"></div>
                        <div className="col-2"> 
                            <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" ><img width="200em" src={googleplay} alt="Links to download" /></a>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </main>
        );
    };
};
