import { Link } from 'react-router-dom';
const Language = [{
    en: {
        directions: <>
            <p className="col-form-label">Fill out and send the form. We will contact you within 24 hours.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:"1rem"}}>We'll never share your email with anyone else.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Text message</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                I accept <Link to="/privacy-police/en" style={{ color: 'purple' }}>Privacy Policy 
                </Link>, as well as <Link to="/user-agreement/en" style={{ color: "purple" }}>Term of Use</Link>.
            </label>
        </>,
        buttonText: <>
            <label className="form-check-label" > Send</label>
        </>
    },
    es: {
        directions: <>
            <p className="col-form-label">Complete y envíe el formulario. Nos comunicaremos con usted en un plazo de 24 horas.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Correo electrónico</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:"1rem"}}>Nunca compartiremos su correo electrónico con nadie más.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Nombre</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Mensaje de texto</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                Estoy de acuerdo con la <Link to="/privacy-police/es" style={{ color: 'purple' }}>Política de privacidad </Link>  y los 
                <Link to="/user-agreement/es" style={{ color: 'purple' }}>Términos de uso</Link>.
            </label>
        </>,
        buttonText: <>
            <label className="form-check-label" >Enviar</label>
        </>
    },
    de: {
        directions: <>
            <p className="col-form-label">Füllen Sie das Formular aus und senden Sie es ab. Wir werden Sie innerhalb von 24 Stunden kontaktieren.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">E-Mail-Addresse</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:"1rem"}}>Wir werden Ihre E-Mail niemals mit anderen teilen.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Textnachricht</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                Ich stimme <Link to="/privacy-police/de" style={{ color: 'purple' }}>den Datenschutzbestimmungen
                </Link> und <Link to="/user-agreement/de" style={{ color: 'purple' }}>Nutzungsbedingungen</Link> zu.
            </label>
        </>,
        buttonText: <>
            <label className="form-check-label" >Senden</label>
        </>
    },
    ua: {
        directions: <>
            <p className="col-form-label">Заповніть та відправте форму. Ми зв'яжемося з вами протягом 24 годин.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Електронна пошта</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted" style={{fontSize:"1rem"}}>Ми ніколи не поділимось вашою електронною поштою з кимось іншим.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Ім'я</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Текстове повідомлення</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:"1rem"}}>
                Я згоден з <Link to="/privacy-police/ua" style={{ color: 'purple' }}>Політикою конфіденційності</Link>, а 
                також <Link to="/user-agreement/ua" style={{ color: 'purple' }}>Умовами використання</Link>.
            </label>
        </>,
        buttonText: <>
            <label className="form-check-label" >Відправити</label>
        </>
    },
}]
export default Language;
//{stateLanguage && languages[stateLanguage].description1}