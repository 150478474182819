
import { Link } from 'react-router-dom';
const Language = [{
    en: {
        directions: <>
            <p className="col-form-label">To order an AR application for advertising or your business, fill out and send the form.</p>
            <p className="col-form-label"> We will contact you within 24 hours.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
           <label htmlFor="exampleTextarea" className="form-label mt-4">Comments to the order</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:"1rem"}}>
                I accept <Link to='/privacy-police' style={{ color: 'purple' }}>Privacy Policy </Link>, as well as <Link to='/user-argeement' style={{ color: 'purple' }}>Term of Use</Link>.
            </label>
        </>,
        buttonText: <>
            Send
        </>
    },
    es: {
        directions: <>
            <p className="col-form-label">Para solicitar una solicitud de RA para publicidad o su negocio, complete y envíe el formulario.</p>
            <p className="col-form-label">Nos comunicaremos con usted en un plazo de 24 horas.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Correo electrónico</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted">Nunca compartiremos su correo electrónico con nadie más.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Nombre</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Comentarios al pedido</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                Estoy de acuerdo con la <Link to='/privacy-police' style={{ color: 'purple' }}>Política de privacidad </Link>  y los <Link to='/user-argeement' style={{ color: 'purple' }}>Términos de uso</Link>.
            </label>
        </>,
        buttonText: <>
            Enviar
        </>
    },
    de: {
        directions: <>
            <p className="col-form-label">Um eine AR-Anwendung für Werbung oder Ihr Unternehmen zu bestellen, füllen Sie das Formular aus und senden Sie es ab.</p>
            <p className="col-form-label"> Wir werden Sie innerhalb von 24 Stunden kontaktieren.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">E-Mail-Addresse</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted">Wir werden Ihre E-Mail niemals mit anderen teilen.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
           <label htmlFor="exampleTextarea" className="form-label mt-4">Kommentare zur Bestellung</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                Ich stimme <Link to='/privacy-police' style={{ color: 'purple' }}>den Datenschutzbestimmungen</Link> und <Link to='/user-argeement' style={{ color: 'purple' }}>Nutzungsbedingungen</Link> zu.
            </label>
        </>,
        buttonText: <>
            senden
        </>
    },
    ua: {
        directions: <>
            <p className="col-form-label">Для замовлення AR додатка для реклами або свого бізнесу, заповніть та відправте форму.</p>
            <p className="col-form-label"> Ми зв'яжемося з вами протягом 24 годин.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Електронна пошта</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted">Ми ніколи не поділимось вашою електронною поштою з кимось іншим.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Ім'я</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Коментарі до замовлення</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse"  style={{fontSize:"1rem"}}>
                Я згоден з <Link to='/privacy-police' style={{ color: 'purple' }}>Політикою конфіденційності</Link>, а також <Link to='/user-argeement' style={{ color: 'purple' }}>Умовами використання</Link>.
            </label>
        </>,
        buttonText: <>
            Відправити
        </>
    },
}]
export default Language;
//{stateLanguage && languages[stateLanguage].description1}