//import React, { useEffect } from "react";
import React  from "react";
import { connect } from "react-redux";

//Language
import Language from "./Language";

//Router
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

//Images
import logoMob from "../../images/AforehandStudio_logo_v2.webp";
//import logoMob2 from "../../images/favicon-192x192.png";

//Buttons
import { createTheme  } from "@mui/material/styles";
import { orange, purple } from "@mui/material/colors";

//Select
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { SaveToLocalStorage } from "../SaveToLocalStorage/SaveToLocalStorage"
import "./Header.css";


const theme = createTheme({
    palette: {
        primary: orange,
    },
});


const checkPathNameForLinkBySwitchingLanguages = (l) => {
    var arr = [ 'en', 'ua', 'es', 'de']; //'EN', 'UA', 'ES', 'DE',
    for (var i = 0; i < arr.length; i++) {
        if (l === arr[i]) return true;
    }
    return false;
}


const Header = ({stateLanguage,  changeLanguage}) => {
    // SaveToLocalStorage("ONCLICKSTYLE", " ")
    const curLanguage = stateLanguage;  
        console.log("stateLanguage Header", curLanguage);
    const locationD = useLocation();
    let navigate = useNavigate();
    const [languages] = Language;
    console.log ("ONCLICKSTYLE header", localStorage.getItem("ONCLICKSTYLE"), ".");
    //const ScrollToHashElement = (section) => {       
        
   /* const handleScroll=(section) =>{
        //var el = document.getElementById(section);
        var el = document.getElementsByClassName(section);
        if (!el){
        el.scrollIntoView({ behavior: 'smooth', block:"start"  });} //block: "nearest" inline: "center" //window.scrollTo(0, 0);
    }*/

    const onSetStyle=(curLang) => {
        console.log("you just clicked:"); //, bl
        SaveToLocalStorage("ONCLICKSTYLE", "scrollPad"); //
       // localStorage.setItem("CLICKED", 1);
            console.log ("newSavedStyle Header:", localStorage.getItem("ONCLICKSTYLE"), " .");
            console.log ("CLICKED Header:", localStorage.getItem("CLICKED"));
      /* var onClickStyle=localStorage.getItem("ONCLICKSTYLE");
        console.log("style before App:", onClickStyle);
        if (st !==onClickStyle) SaveToLocalStorage ("ONCLICKSTYLE", "scrollPad");
        console.log("style after App:", ocalStorage.getItem("ONCLICKSTYLE"));*/   
        var pathRef = "/main/"+ curLang + "#AR"; 
        navigate (pathRef);        
    }


    const GoHome=(curLang) =>{        
        var pathHomeLang = "/main/"+ curLang; 
        //console.log("pathHomeLang GoHome", pathHomeLang)
        
        SaveToLocalStorage ("ONCLICKSTYLE", " "); 
        navigate (pathHomeLang); 
        //window.scrollTo(0, 0); 
    }

    const pathNameLang = (NewLG) => {           
        //console.log("NewLG", NewLG);
        var s=locationD.pathname.split('/').length; 
            //console.log("length s", s);           
            //console.log("checkPathNameForLinkBySwitchingLanguages"  , checkPathNameForLinkBySwitchingLanguages(locationD.pathname.split('/')[s-1]));
        var oldLang =locationD.pathname.split('/')[s-1];
        console.log("locationD.pathname",locationD.pathname);
            //console.log("splitLast",oldLang);
            
            if ( checkPathNameForLinkBySwitchingLanguages(oldLang) ) { 
                var pathnameMy =locationD.pathname.replace( oldLang,"") ;                
                //console.log("pathnameMy true", pathnameMy);
                var pathnameNew = pathnameMy+NewLG; 
                //console.log("pathnameNew true", pathnameNew);
            }
            else {             //changeLanguage('EN');                                                
                var pathnameNew = locationD.pathname + "/"+ NewLG;  
                //console.log ("pathnameNew False", pathnameNew);
            }
            return pathnameNew;
        }
        
 
    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: "white", 
                borderBottom: '1px solid orange', paddingTop: '0.0rem', paddingBottom: '0.0rem',
                paddingLeft: "0.0%", paddingRight: '0rem',
                borderBottomWidth:'1.2px' }} > 

               
                <div className="container" style={{ paddingLeft: "0%", paddingBottom: "0%" }}> {/* <div className="row" > */} 
                                
                    <div className="container" style={{marginBottom:"0.4em"}}> {/*<div className="header2" >*/}
                        <div className="row" > {/*row  display: 'flex'    <div className="row" >*/}
                            {/*header-logo*/}        
                            <div className="col " >
                            <a href={"/main/"+stateLanguage} onClick={() => {  GoHome(stateLanguage); }} >             {/*navigate("/"); */}                 
                                <img src={logoMob} style={{ padding: "0 0 0 0" }} alt="Logo"></img>
                            </a>
                            </div>
                        
                            {/* center header*/}
                            <div className="col-8 ">
                                <div className="header-right" >
                                    <div className="row" style={{paddingTop: "18px"}}>
                                        {/* td_block1*/}
                                        <div className="col"  style={{marginLeft : "10%", marginTop: "0.25em",  fontSize: "1.25em",  textDecorationStyle: "underline"}} 
                                            onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " "); }}>   {/* td_block1  nav-link content_size width="30%" style={{textAlign:"left"}}>*/}                               

                                            {stateLanguage === "en" 
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy" > GAMES  </a>//onClick={() =>onSetStyle("block1")}//hover:"orange", textDecorationColor:"orange" style={{ color: "purple" }}  */                                                                                                                                                                                       
                                            :stateLanguage === "es" 
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy"> JUEGO  </a> //onClick={() => onSetStyle("block1")} style={{ color: "purple" }}                                               
                                            : stateLanguage === "ua" 
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy"> ІГРИ </a>//onClick={() => onSetStyle("block1")}  style={{ color: "purple" }}                                                                                            
                                            : stateLanguage === "de"
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy"> SPIELE </a>//onClick={() => onSetStyle("block1")}  style={{ color: "purple" }}                                                                                        
                                            :stateLanguage === ""
                                            }
                                        </div> 

                                        {/*td_block2"> */}
                                        <div className="col "style={{ textAlign: "center", marginLeft : "0%", marginRight : "10%",marginTop: "0.25em", fontSize: "1.25em" }}
                                            onClick={() =>{onSetStyle(stateLanguage)}} >
                                            {stateLanguage === "en" 
                                            ? <a href={"/main/"+stateLanguage} className="fadeMy"   >AR-projects</a> //onSetStyle()
                                            :stateLanguage === "es" 
                                            ? <a href={"/main/"+stateLanguage} className="fadeMy"  >Proyectos de AR </a>     //onClick={() =>{onSetStyle()}}
                                            : stateLanguage === "ua" 
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy"  >AR проекти  </a> 
                                            :stateLanguage === "de"
                                            ?<a href={"/main/"+stateLanguage} className="fadeMy"   >AR-Projekte  </a>
                                            :stateLanguage === ""   
                                            }
                                        </div>
                                        
                                        {/*td_buttom">*/}
                                        <div className="col " style={{ marginTop: "0.0em", textAlign: "center" }}    //  width="10%" style={{textAlign:"right"}}>*/}
                                           onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " "); }} >
                                            {stateLanguage === "en"
                                            ? <Link to={"/orderApp/"+stateLanguage}  >
                                            <button className="btn btn-outline-primary buttonStyle" >AR-app 4you</button></Link>   //*onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " ");}*/
                                            :stateLanguage === "es"
                                            ? <Link to={"/orderApp/"+stateLanguage}  > {/*onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " "); }}* */}
                                            <button className="btn btn-outline-primary buttonStyle" >App-RA para ti</button></Link> 
                                            : stateLanguage === "ua"
                                            ? <Link to={"/orderApp/"+stateLanguage}  > {/*onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " "); }} */}
                                            <button className="btn btn-outline-primary buttonStyle" >Твій AR-додаток</button></Link>
                                            : stateLanguage === "de"
                                            ? <Link to={"/orderApp/"+stateLanguage} > {/*onClick={() => { SaveToLocalStorage("ONCLICKSTYLE", " "); }} */}
                                            <button className="btn btn-outline-primary buttonStyle" >AR-App für Sie</button> </Link>
                                            :stateLanguage === ""
                                            }   
                                        </div>
                                    </div>
                                </div>
                            </div>                                                                         

                            {/*Languige switch */}  
                            <div className="col " >
                                <div className="row" style={{paddingTop: "20px"}}>
                                <div className="col">
                                    <div className="styleLang" id="account" style={{ padding: 0 }}>
                                <FormControl >
                                  <NativeSelect inputProps={{ name: "name", id: "lang", } }
                                    sx={[ 
                                        {"&:hover": {
                                            color: purple[400],
                                            borderBottom: "0.5px solid rgb(128, 0, 128)",
                                            boxShadow: 6,
                                        backgroundColor: orange[400]}
                                        },
                                        { "&::after" : {
                                            borderBottom: "1px solid rgb(128, 0, 128)"}
                                        }                                       
                                        ] }
                                        
                                    onChange={(e) => {
                                        var newLang = e.target.value; 
                                        changeLanguage(newLang);
                                        var pathNew = pathNameLang(newLang);   
                                        console.log("pathNew onChange", pathNew);
                                        //window.scrollTo(0, 0);
                                        navigate (pathNew);                                         
                                    }}
                                    value={stateLanguage}                                    
                                    >
                                    <option value="en">en</option>
                                    <option value="de">de</option>
                                    <option value="es">es</option>
                                    <option value="ua">ua</option>
                                     </NativeSelect>
                               
                             
                                     {/*   //var pathnameMy = locationD.pathname; //.split('/')[1];//document.location.pathname.split('/')[1];                                        
                                    /*var s=locationD.pathname.split('/').length;*/
                                    /*    console.log("locationD", locationD);
                                        /*console.log("pathnameMy", pathnameMy);
                                        console.log("split parts", locationD.pathname.split('/').length);
                                        console.log("split0", locationD.pathname.split('/')[0]);
                                        console.log("split0", locationD.pathname.split('/')[1]);*/
                                            //locationD.pathname.split('/')[s-1] = e.target.value.toLowerCase();
                                            //document.location.href = locationD.pathname+ e.target.value.toLowerCase();
                                            //document.location.href = locationD.pathname.split('/')[s-1] + e.target.value.toLowerCase();
                                    /*        var pathnameMy =locationD.pathname.replace({oldLang},"");
                                            console.log("pathnameMy", pathnameMy);   */
                                            //document.location.href = pathnameMy+ e.target.value.toLowerCase();
                                    /*    }   
                                        else {   */}                                         
                                    {/*        changeLanguage('EN');   */ }
                                     {/*               //changeLanguage(e.target.value);
                                            //pathnameMy += '/';
                                            //console.log("pathnameMy", pathnameMy);*/}
                                    {/*        document.location.href =locationD.pathname+"/" + e.target.value.toLowerCase();
                                            console.log ("document.location.hrefFalse", document.location.href);
                                        }*/}
                                     {/*   //var newLink = document.location.origin + "/" + pathnameMy + e.target.value.toLowerCase();
                                        //var newLink =pathnameMy + e.target.value.toLowerCase();

                                        //document.location.href = newLink;
                                        */}
                                                                    
                                </FormControl>                                                                                            
                                    </div>
                                </div> 
                                </div>
                            </div> 
                        
                        </div>
                    </div>
                </div>

            </nav>
            </React.Fragment>

        );
    };       

const mapStateToProps = ({ SignInReducer }) => {
    return {
        token: SignInReducer.token,
        name: SignInReducer.name
    }
}

export default connect(mapStateToProps)(Header);
