//import React, { useEffect, useState } from "react";
import React from "react";

import hb1 from "../../images/hb_v1.png";
import mishka1 from "../../images/mishka_v1.png";
import marmelad1 from "../../images/marmelad_v1.png";
import sakura1 from "../../images/sakura_v1.png";
import googleplay from "../../images/GooglePlay.png";
//import { connect } from "react-redux";
//import ReactPlayer from "react-player/youtube"
//Components
//import ModalDefault from "../../Components/ModalDefault/ModalDefault";

//Language
import Language from "./Language";

//router
//import { useLocation } from "react-router-dom";
//import { changeStateModalBuy, changeStateModalUse } from "../../Redux/Actions/ProductsActions";

//Buttons
import { createTheme, ThemeProvider } from "@mui/material/styles";
//import Button from "@mui/core/Button";
import { orange } from "@mui/material/colors";

//style
import "./CardOfProduct.css";

export default class CardOfProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            VideoSize: {
                width: 0,
                height: 0
            },
            ScreenSize: {
                width: 0,
                height: 0
            },
            stateLanguage: { ...props }
        };
        this.myTopOrder = React.createRef();
    }    

    convertStrWidthToInt = (x) => {
        return parseFloat(x.split('px')[0]);
    };

    resetVideoSize = () => {
        var videoWidth = 0;
        var videoHeight = 0;
        if (!window.mobileCheck()) {
            //videoWidth=(document.getElementById("divCol10WithVideo") ? document.getElementById("divCol10WithVideo").clientWidth-document.getElementById("divCol10WithVideo").offsetLeft : 100);
            videoWidth = (document.getElementById("divCol10WithVideo") ? this.convertStrWidthToInt(getComputedStyle(document.getElementById("divCol10WithVideo")).width) : 10);
            if (this.state.ScreenSize.height <= 1080) {
                videoWidth *= (this.state.ScreenSize.height / 1080 * 0.9).toFixed(4);
            }
        }
        else {
            videoWidth = 10;
        }
        videoHeight = videoWidth * 16 / 9;
        var VideoSize = { width: videoWidth, height: videoHeight };
        this.setState({
            VideoSize,
            ScreenSize: {
                width: window.screen.availWidth,
                height: window.screen.availHeight
            }
        });        
    };

    checkUpdates = () => {
        //window.scrollTo({top:0,  behavior: "smooth"});//0, 0);
        this. resetVideoSize();
    }   

    componentDidMount() {                      
        this.setState({
            VideoSize: {
                 width: 0,
                height: 0
            },
            ScreenSize: {
                width: window.screen.availWidth,
                height: window.screen.availHeight
            },
            stateLanguage: this.props.stateLanguage,           
        });

        document.addEventListener("DOMContentLoaded",
            () => { this.checkUpdates();  }
        );
        this.checkUpdates();   
        window.scrollTo({top:0,  behavior: "smooth"});    //0, 0);     
        //this.myTopOrder.current.scrollIntoView({ behavior: 'smooth', block:"start"  }); 
    }
    
    componentDidUpdate() {
        if ((this.state.VideoSize.width === 10) && ((document.getElementById("divCol10WithVideo") ? 
        this.convertStrWidthToInt(getComputedStyle(document.getElementById("divCol10WithVideo")).width) : false))) {          
        this.checkUpdates();
        }
        window.scrollTo({top:0,  behavior: "smooth"}); 
        //document.getElementById("view").scrollTo({ top: 0, behavior:  'smooth'  });
        //this.myTopOrder.current.scrollIntoView({ behavior: 'smooth', block:"start"  }); 
    }

    render() {
        //var { stateLanguage } = this.state;  
        //window.scrollTo({top:0,  behavior: "smooth"});    //0, 0);   
        
        //document.getElementById("view").scrollTo({ top: 0, behavior:  'smooth'  });
        const [languages] = Language;
        var stateLanguage   = this.props.stateLanguage;
        const mobVers = <React.Fragment>
            <div className="col-12" style={{ margin: '60px auto 0' }} ref={this.myTopOrder}>
                <div className="row col-12">
                    <div className="card-product"></div>
                    <div className="product__banner video2">
                        <div className="product__banner-text">
                            <h4 style={{ textAlign: "center" }}>
                                {stateLanguage && languages[stateLanguage].description1}
                            </h4>

                            {/*<p */}
                            <span style={{ textAlign: "justify", paddingTop:'1em', paddingBottom:'1em', marginBottom:'0' }}>
                                {stateLanguage && languages[stateLanguage].description2}
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1452173617-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={hb1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452193872-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={mishka1} alt="текст" /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1454466395-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={marmelad1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452196465-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={sakura1} alt="текст" /></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                {stateLanguage && languages[stateLanguage].description3}
                            </span>
                            {/*</p>*/}
                        </div>

                        <div className="col-12" >
                            <div className="row">
                                <div className="col-12" >
                                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/d_Epz7abhq8?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                <iframe src="https://www.youtube.com/embed/d_Epz7abhq8?controls=0" 
                                title="YouTube video player" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen></iframe>
                                
                                    {/* <iframe className="asdf" height={16 * window.screen.availWidth / 9 * 0.8} width={window.screen.availWidth * 0.8} src="https://www.youtube.com/embed/d_Epz7abhq8?loop=1&autoplay=1&playlist=d_Epz7abhq8" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen  ></iframe> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col"></div>
                                <div className="col-6" style={{ paddingTop: "1em", paddingBottom: "1em" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ textAlign: "center" }} ><img width="200em" src={googleplay} alt="Links to download" /></a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>

                    </div>

                    <div  id="giftSet">
                        {stateLanguage && languages[stateLanguage].giftSet}
                    </div>

                    <div className="product__instruction">

                        <div style={{ fontSize: "1.25em", paddingBottom: "0.5em" }}>
                            {stateLanguage && languages[stateLanguage].instructions}
                        </div>
                        <ol className="instruction-list">
                            {stateLanguage && languages[stateLanguage].instructionList}
                        </ol>
                    </div>
                </div>
            </div>
        </React.Fragment>;
{/*//!!!!!!!!!!!!!!!!! */}
        const normalVers = < React.Fragment>
        
       {/*} <div className='col-10' > */}  {/*}style={{ margin: '0 auto' }}*/}
            <div className='row '>
                <div className="product__banner " >  {/*col-11" style={{ margin: '0 auto' }}>*/} {/*<div className="row "> */}{/*col-12*/} {/*<div className="card-product"></div>*/}
                    <h5 style={{textTransform: "uppercase", color: "purple"}}> 
                    {stateLanguage && languages[stateLanguage].description1} 
                    </h5> {/*style={{ textAlign: "center" }}*/}
                </div> 

                {/*}<div className="product__banner col-12" >  {/* style={{display: "flex"}}*/}
                    {/*}<div className="col-6 videoWrapper" > */}{/*className= "videoWrapper" id="divCol10WithVideo"
                            <iframe className= "video-css" //width ="100%"  //width="560" height="315"  //width= {this.state.VideoSize.width}   //this.state.VideoSize.width}  //"340px"  "360px" //                                      
                                                src="https://www.youtube.com/embed/d_Epz7abhq8?loop=1&playlist=d_Epz7abhq8"  //&autoplay=1&mute=1
                                                title="YouTube video player2" allow="autoplay; encrypted-media"
                                                allowFullScreen >                                                                                                
                            </iframe>   
                    </div>
                    <div className="asdf col-6" >  {/*style={{ fontSize: "1.25em", marginTop: "0.75em", marginBottom: "0.75em"}}  style={{alignSelf: "center"}}*/}
                        {/* <div >
                        {stateLanguage && languages[stateLanguage].description2} 
                        <p style={{ marginTop: "0.75em", marginBottom: "0.75em"}}> 
                            <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" >
                            <img width="200em"  src={googleplay} alt="Links to download" /></a>  
                        </p> 
                        <p > 
                        {stateLanguage && languages[stateLanguage].description2_2}     
                        </p> 
                        </div>
                 </div>
                 </div> */}
                {/*<br />*/}
                <div className="product__banner col-12">
                <table > {/*className="product__banner"*/}
                    <tbody >
                    <tr>
                        <td  width ="30%"> 
                                        {/*<ReactPlayer  url='https://www.youtube.com/watch?v=d_Epz7abhq8&list=TLGGPSHdMZ3mxIwzMTEwMjAyNA' 
                                         loop='true' playing='false' muted='true' /> */}
                           {/*} <div className="videoWrapper" > */}{/*className= "videoWrapper"*/}
                                <iframe className= "video-css"  //width="560" height="315"  //width= {this.state.VideoSize.width}   //this.state.VideoSize.width}  //"340px"  "360px" //                                      
                                        src="https://www.youtube.com/embed/d_Epz7abhq8?loop=1&playlist=d_Epz7abhq8&autoplay=1&mute=1&" //
                                        title="YouTube video player" //allow="autoplay; encrypted-media"
                                        allowFullScreen >                                                                                                
                                </iframe> 
                           {/*} </div>*/}
                        </td> 
                        <td width="3%">
                        </td>
                        <td width ="60%">
                            <tr>
                                <div > 
                                <p  style={{ textAlign:"left", fontSize: "1.25em"}}>
                                    {stateLanguage && languages[stateLanguage].description2}                                                     
                                {/*</p>                                                                         
                                <p style={{ fontSize: "1.2em", marginTop: "0.5em", marginBottom: "0.5em", textAlign: "right"}}> */}
                                    <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" >
                                    <img width="200em"  src={googleplay} alt="Links to download" />
                                    </a>  
                                </p>
                                <p  style={{ fontSize: "1.25em", textAlign: "justify" }}>
                                    {stateLanguage && languages[stateLanguage].description2_2}     
                                </p>  
                                </div>            
                            </tr>
                            <tr>
                                <p  style={{ fontSize: "1.25em",textAlign: "left", marginTop: "0.25em"}}> {/*className="instruction-list"  style={{  textAlign: "left", marginLeft:"25px", paddingTop:"25px" }}>*/}
                                    {stateLanguage && languages[stateLanguage].description2_3}     
                                </p> 
                            </tr>
                            <tr>
                                <table>
                                    <tbody>
                                    {/*<tr></tr>*/}
                                    <tr>
                                        <td><a href={"/ar-koza-party/ARimages/"+stateLanguage} > <img width="100%" height="100%" src={hb1} alt="HappyBirthday AR-Set" />  </a>
                                            {/*<a href="https://prom.ua/ua/p1404126566-nabor-posudy-koza.html"><img width="100%" height="100%" src={hb1} alt="HappyBirthday AR-Set" /></a>*/}
                                        </td>
                                        <td><a href={"/ar-koza-party/ARimages/"+stateLanguage} > <img width="100%" height="100%" src={mishka1} alt="Mishka AR-Set"  />  </a>
                                        {/*<a href="https://kharkov.prom.ua/p1452193872-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={mishka1} alt="Mishka AR-Set" /></a>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href={"/ar-koza-party/ARimages/"+stateLanguage} > <img width="100%" height="100%" src={marmelad1} alt="Marmelad AR-Set" />  </a>
                                          {/*  <a href="https://kharkov.prom.ua/p1454466395-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={marmelad1} alt="Marmelad AR-Set" /></a>*/}
                                        </td>
                                        <td> <a href={"/ar-koza-party/ARimages/"+stateLanguage} > <img width="100%" height="100%" src={sakura1} alt="Sakura AR-Set" />  </a>
                                          {/*}  <a href="https://prom.ua/ua/p1529672518-nabor-posudy-koza.html"><img width="100%" height="100%" src={sakura1} alt="Sakura AR-Set" /></a></td>*/}
                                        </td>  
                                    </tr>
                                    <tr></tr>
                                    </tbody>
                                </table>
                            </tr>
                            <tr>
                                <p style={{ fontSize: "1.25em", textAlign: "left" }}>{/*marginTop:"0.75em" */}
                                    {stateLanguage && languages[stateLanguage].description3}
                                </p> 
                            </tr>
                        </td>
                    </tr>        
                    </tbody>
                </table>
                </div>


                <div className="product__banner col-12">
                <p  style={{ textAlign: "center", marginBottom:"0.75em" }}>
                    {stateLanguage && languages[stateLanguage].description3_1}  
                </p>
                {/*  <table>
                                    <tbody>
                                    <tr></tr>
                                    <tr>
                                        <td><a href="https://prom.ua/ua/p1404126566-nabor-posudy-koza.html"><img width="100%" height="100%" src={hb1} alt="HappyBirthday AR-Set" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452193872-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={mishka1} alt="Mishka AR-Set" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1454466395-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={marmelad1} alt="Marmelad AR-Set" /></a>
                                        </td>
                                        <td><a href="https://prom.ua/ua/p1529672518-nabor-posudy-koza.html"><img width="100%" height="100%" src={sakura1} alt="Sakura AR-Set" /></a></td>
                                    </tr>
                                    <tr></tr>
                                    </tbody>
                                </table>
                        <p style={{ textAlign: "left", marginTop:"0.75em" }}>
                                    {stateLanguage && languages[stateLanguage].description3}
                        </p> */}
                </div>

                <div className="product__instruction col-12">
                        <p style={{ fontSize: "1.25em", paddingBottom: "0.5em" }}>
                            {stateLanguage && languages[stateLanguage].instructions}
                        </p>
                        <ol className="instruction-list">
                            {stateLanguage && languages[stateLanguage].instructionList}
                        </ol>

                </div>                                        

                    {/*<p style={{ fontSize: "1.25em", paddingTop: "1em" }} id="giftSet">
                        {stateLanguage && languages[stateLanguage].giftSet}
                    </p> */}
            </div>
        {/*</div>*/}
        </React.Fragment>;    



        return (
            <main className="main" >    {/*style={{marginTop: "9em"}}*/}
                <div className="container">
                    <div className='row' ref={this.myTopOrder}>
                        {this.state.ScreenSize.width <= 479 ? mobVers : normalVers}
                    </div>
                </div>

            </main>
        );
    };


};