//import React, {useEffect} from 'react'
import React  from 'react'
//Final form
import {Field, Form} from "react-final-form";
import TextField from "@mui/material/TextField";

//Router
import {Link} from "react-router-dom";

//Language
import Language from "./Language";

//Style
import "./ResetPass.css";

import HomeIcon from '@mui/icons-material/Home';

//style
import './ResetPass';
//import {asyncForgotPass, asyncNewPass} from "../../Redux/Actions/SignInActions";
import {asyncNewPass} from "../../Redux/Actions/SignInActions";
import {ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import {orange} from "@mui/material/colors";
import {connect} from "react-redux";
//import {changeStateModalRestorePass} from "../../Redux/Actions/HeaderActions";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
//import {SignInReducer} from "../../Redux/Reducers/SignInReducer";

const theme = createTheme({
    palette: {
        primary: orange,
    },
});
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #FF9800",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));



const ResetPass = ({dispatch,stateLanguage,stateModalSuccessRestorePass}) => {

    const classes = useStyles(); //  If you have this, consider moving it
    // inside of a component wrapped with <ThemeProvider />

    //destructuring language file
    const [languages] = Language;

    return (
        <main className="main">
            <p className='titlePass' >{stateLanguage ? languages[stateLanguage].title : null}</p>
            
            <Modal
                className={classes.modal}
                open={stateModalSuccessRestorePass}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={stateModalSuccessRestorePass}>
                    <div className={classes.paper}>
                        <p> {stateLanguage ? languages[stateLanguage].saved : null} <Link to='/YoReminder'><HomeIcon/></Link></p>
                    </div>
                </Fade>
            </Modal>
            <Form 
            
            onSubmit={(formObj) => {
                formObj.adress = window.location.href
                dispatch(asyncNewPass(formObj));
            }}
            validate={(values) => {
                const errors = {};
                if (!values.passw) {
                    errors.passw = 'requared';
                } else if(values.passw.length < 6) {
                    errors.passw = 'minimum password length 6 characters';
                }
                if(values.newPass !== values.passw){
                    errors.newPass = 'password not much';
                }
                return errors;
            }}
            render={({handleSubmit, invalid}) => (
                <form onSubmit={handleSubmit} className="pop-up__form-style" style={{textAlign:"center"}}>
                    <Field name="passw">
                        {({input, meta}) => (
                            <div className="new-pass">
                                <TextField
                                    required
                                    id="password"
                                    label='new password'
                                    type="password"
                                    {...input}
                                />
                                {meta.error && meta.touched && (
                                    <span className="error-form">{meta.error}</span>
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name="newPass">
                        {({input, meta}) => (
                            <div className="new-pass">
                                <TextField
                                    required
                                    id="password-2"
                                    label='confirm new password'
                                    type="password"
                                    {...input}
                                />
                                {meta.error && meta.touched && (
                                    <span className="error-form">{meta.error}</span>
                                )}
                            </div>
                        )}
                    </Field>
                    <ThemeProvider theme={theme}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="block-form__main-button purple-button"
                            disabled={invalid}
                            //#type="submit" 
                        >
                            {stateLanguage ? languages[stateLanguage].button : null}
                        </Button>
                    </ThemeProvider>
                </form>
            )}
        />
        </main>
    );
}


const mapStateToProps = ({HeaderReducer,SignInReducer}) => {
    return {        
        stateModalRestorePass: HeaderReducer.stateModalRestorePass,
        stateModalSuccessRestorePass: SignInReducer.stateModalSuccessRestorePass,
    }
}

export default connect(mapStateToProps)(ResetPass);