import React from 'react';

const ARPuzzle = ({stateLanguage})=>{
    
    
    return <main className="main">
        <div className="container">
            <h1>
            ArPuzzle
            </h1>
            </div>
        </main>;
}

export default ARPuzzle;