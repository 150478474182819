import React from 'react'
import hb1 from "../../images/hb_v1.png"
import mishka1 from "../../images/mishka_v1.png"
import marmelad1 from "../../images/marmelad_v1.png"
import sakura1 from "../../images/sakura_v1.png"
import { Link } from 'react-router-dom';
import * as emailjs from 'emailjs-com';
import Language from "./Language";
import './deliveryForm.css';

export default class DeliveryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLanguage: { ...props },
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isAdressOk: false,
            isNumberOk: false,
            isTermsOfUseOk: false,
            Info: {
                username: "",
                mailfrom: "",
                message: "",
                number: "",
                adress: "",
                termsOfUse: false
            },
            sendFormPropertis: {
                service: "service_gf42tma",
                template: "template_oeasndb",
                selector: "#mailform",
                userId: "user_zr3jNsGItXGHKG7MIfu8J"
            }
        };
    }

    componentDidUpdate() {
        console.log(this.state)
    }
    componentDidMount() {
        this.setState({
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isAdressOk: false,
            isMessageOk: false,
            isNumberOk: false,
            isTermsOfUseOk: false
        });
    }
    onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        var Info = this.state.Info;
        Info[name] = value;
        this.setState({ Info });
    };

    checkFields = () => {
        var inputEmail = document.getElementById("exampleInputEmail1")?.value;
        var inputName = document.getElementById("exampleInputEmail2")?.value;
        var message = document.getElementById("exampleTextarea")?.value;
        var number = document.getElementById("numberArea")?.value;
        var adress = document.getElementById("adressArea")?.value;
        var termsOfUse = document.getElementById("termsOfUse")?.checked;


        var Info = {
            mailfrom: inputEmail,
            username: inputName,
            number,
            message,
            adress
        };

        var isEmailOk = inputEmail && (inputEmail.indexOf('@') > -1) && (inputEmail.indexOf('.') > -1);
        var isNameOk = inputName && true;
        // var isMessageOk = message && true;
        var isAdressOk = adress && true;
        var isNumberOk = (number?.length > 0) && (!isNaN(number)) && (parseInt(number) > 0);
        var isTermsOfUseOk = termsOfUse && true;


        this.setState({ isEmailOk, isNameOk, isNumberOk, isAdressOk, isTermsOfUseOk, Info });
    }

    sendMessege = (e) => {
        const { service, template, userId } = this.state.sendFormPropertis;
        const { Info } = this.state;

        var templateParams = {
            head: "Aforehand Studio Міжнародна доставка",
            username: Info.username,
            mailfrom: Info.mailfrom,
            message1: "Адреса доставки: " + Info.adress,
            message2: "Кількість наборів: " + Info.number,
            message3: "Повідомлення: " + Info.message
        };
        emailjs
            .send(
                service,
                template,
                templateParams,
                userId
            );
        this.setState({ IsSended: true });
    };

 

    render() {
        const [languages] = Language;
        const { stateLanguage } = this.props;
        if (this.state.IsSended) {
            const notification1 = stateLanguage === "en" ? "Message sent successfully!" :
                        stateLanguage === "es" ? "¡Mensaje enviado con éxito!" :
                         stateLanguage === "ua" ? "Повідомлення успішно надіслано!" :
                        "Nachricht erfolgreich gesendet!";
            const notification2 = stateLanguage === "en" ? " You will be contacted shortly." :
                        stateLanguage === "es" ? " Nos comunicaremos con usted en breve." :
                        stateLanguage === "ua" ? " Незабаром з вами зв'яжуться." :
                        " Sie werden in Kürze kontaktiert.";
            const buttontext = stateLanguage === "en" ? "Return to the main page" :
                        stateLanguage === "es" ? "Regresar a la pagina principal" :
                        stateLanguage === "ua" ? "Повернення до головної сторінки" :
                        "Zurück zur Hauptseite";
            return (

                <main className="main">

                    <div className=" container alert alert-dismissible alert-primary col-7 alert" >
                        <br />
                        {/*<a style={{color:"black"}}>{notification1}{notification2}</a> */}
                        <i style={{color:"black"}}>{notification1}{notification2}</i> 
                        <br />
                        <br />
                        <div className="row">
                            <Link className="col-12" to="/main" style={{ float: 'right' }} >
                                <button className="btn btn-info buttonStyle" >{buttontext}</button>
                            </Link>
                        </div>
                        <br />
                    </div>
                </main>
            );
        }
        const emailplaceholder = stateLanguage === "en" ? "Enter your email" :
                            stateLanguage === "es" ? "Introduce tu correo electrónico" :
                            stateLanguage === "ua" ? "Введіть свою електронну пошту" :
                            "Geben sie ihre E-Mail Adresse ein";
        const nameplaceholder = stateLanguage === "en" ? "Enter your name" :
                            stateLanguage === "es" ? "Introduzca su nombre" :
                            stateLanguage === "ua" ? "Введіть своє ім'я" :
                            "Gib deinen Namen ein";

        const numberplaceholder = stateLanguage === "en" ? "Enter the number of AR-sets to deliver" :
                            stateLanguage === "es" ? "Ingrese la cantidad de sets de AR-para entregar" :
                            stateLanguage === "ua" ? "Введіть кількість AR-наборів для доставки" :
                            "Geben Sie die Anzahl der zu liefernden AR-sets ein";

        const adressplaceholder = stateLanguage === "en" ? "Enter the delivery address" :
                            stateLanguage === "es" ? "Ingrese la dirección de entrega" :
                            stateLanguage === "ua" ? "Введіть адресу доставки" :
                            "Geben Sie die Lieferadresse ein";

        const warningtext = stateLanguage === "en" ? "Required field" :
                            stateLanguage === "es" ? "Campo requerido" :
                            stateLanguage === "ua" ? "Обов'язкове поле" :
                            "Pflichtfeld";


        // const scrWidth = window.innerWidth;
        // const scrHeight = window.innerHeight;


        const scrWidth = window.screen.availWidth;
        //#const scrHeight = window.screen.availHeight;

        var content;
        //#region forms
        if (scrWidth > 479) {
            content =
                <form id="mailform" className="col-7 justify-content-center cont2">

                    <div className="form-group row description_text" style={{ paddingLeft: '2%', paddingTop: '3%', marginBottom: '0%' }}>
                        {stateLanguage === "en"
                            ? <p className="col-form-label">Fill out and send the form to order AR-sets:</p>
                            : stateLanguage === "es"
                                ? <p className="col-form-label">Complete y envíe el formulario para solicitar sets AR:</p>
                                : stateLanguage === "ua"
                                    ? <p className="col-form-label"> До кожного AR-набору входять:</p>
                                    : <p className="col-form-label">Füllen Sie das Formular aus und senden Sie es ab, um AR-sets zu bestellen:</p>}
                    </div>
                    <div>
                        <br />
                        <div className="row">
                            <div className="col-8">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '1. "Happy Birthday" design'
                                        : stateLanguage === "es"
                                            ? '1. Diseño de "feliz cumpleaños"'
                                            : stateLanguage === "ua"
                                                ? '1. Дизайн "Happy Birthday"'
                                                : '1. "Alles Gute zum Geburtstag" Design'
                                    }
                                </b>
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 30 штук'
                                                    : 'Kartonglas (250 ml) - 30 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'laminated cardboard plate (diameter 23 cm) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'placa de cartón laminado (diámetro 23 cm) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'тарілка ламінована картонна (діаметр 23 см) - 30 штук'
                                                    : 'laminierter Kartonteller (Durchmesser 23 cm) - 30 Stück'
                                        }
                                    </li>
                                    <br />
                                </ul>
                            </div>
                            <div className="col-4">
                                <img width="100%" height="100%" src={hb1}/> 
                                {/* alt="Happy Birthday picture" /> */}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '2. "Bear" design'
                                        : stateLanguage === "es"
                                            ? '2. Diseño de "Оso"'
                                            : stateLanguage === "ua"
                                                ? '2. Дизайн «Ведмедик»'
                                                : '2. "Bär"-Design'
                                    }
                                </b>
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 20 штук'
                                                    : 'Kartonglas (250 ml) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'laminated cardboard plate (diameter 23 cm) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'placa de cartón laminado (diámetro 23 cm) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'тарілка ламінована картонна (діаметр 23 см) - 20 штук'
                                                    : 'laminierter Kartonteller (Durchmesser 23 cm) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard cap (height 18 cm) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'tapa de cartón (altura 18 cm) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'картонний ковпак (висота 18 см) - 20 штук'
                                                    : 'Pappkappe (Höhe 18 cm) - 20 Stück'
                                        }
                                    </li>
                                </ul>

                            </div>
                            <div className="col-4">
                                <img width="100%" height="100%" src={mishka1} alt="текст" style={{ marginLeft: "5px" }} />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '3. Design "Marmalade"'
                                        : stateLanguage === "es"
                                            ? '3. Diseño "Mermelada"'
                                            : stateLanguage === "ua"
                                                ? '3. Дизайн "Мармелад"'
                                                : '3. Design "Marmelade"'
                                    }
                                </b>
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 20 штук'
                                                    : 'Kartonglas (250 ml) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'laminated cardboard plate (diameter 23 cm) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'placa de cartón laminado (diámetro 23 cm) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'тарілка ламінована картонна (діаметр 23 см) - 20 штук'
                                                    : 'laminierter Kartonteller (Durchmesser 23 cm) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard cap (height 18 cm) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'tapa de cartón (altura 18 cm) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'картонний ковпак (висота 18 см) - 20 штук'
                                                    : 'Pappkappe (Höhe 18 cm) - 20 Stück'
                                        }
                                    </li>
                                </ul>

                            </div>
                            <div className="col-4">
                                <img width="100%" height="100%" src={marmelad1} alt="текст" />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '4. "Sakura" design'
                                        : stateLanguage === "es"
                                            ? '4. Diseño "Sakura"'
                                            : stateLanguage === "ua"
                                                ? '4. Дизайн "Сакура"'
                                                : '4. "Sakura" Design'
                                    }
                                </b>
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 30 штук'
                                                    : 'Kartonglas (250 ml) - 30 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'laminated cardboard plate (diameter 23 cm) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'placa de cartón laminado (diámetro 23 cm) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'тарілка ламінована картонна (діаметр 23 см) - 30 штук'
                                                    : 'laminierter Kartonteller (Durchmesser 23 cm) - 30 Stück'
                                        }
                                    </li>
                                    <br />
                                    <br />
                                </ul>

                            </div>
                            <div className="col-4">
                                <img width="100%" height="100%" src={sakura1} alt="текст" />
                            </div>
                        </div>
                        {stateLanguage === "en"
                            ? 'A total of 180 items in the sets (box volume 0.018 cubic meters, box weight 2.59kg ).'
                            : stateLanguage === "es"
                                ? <div>
                                    Un total de 180 artículos en el sets<br/>(volumen de la caja 0,018 metros cúbicos, peso de la caja 2.59kg ).
                                    </div>
                                : stateLanguage === "ua"
                                    ? "Всього в наборі 180 предметів (об'єм коробки 0,018 куб. м, вага коробки 2.59кг )."
                                    : 'Insgesamt 180 Artikel im sets (Boxvolumen 0,018 Kubikmeter, Boxgewicht 2.59kg ).'
                        }
                        <br />
                        <b className="b-size">
                            {stateLanguage === "en"
                                ? 'Price:'
                                : stateLanguage === "es"
                                    ? 'Precio:'
                                    : stateLanguage === "ua"
                                        ? 'Ціна:'
                                        : 'Preis:'
                            }
                        </b>
                        {stateLanguage === "en"
                            ? ' 15 $ / sets (without delivery).'
                            : stateLanguage === "es"
                                ? ' 15 $ / colocar (sin entrega).'
                                : stateLanguage === "ua"
                                    ? ' 15 $ / набір (без доставки).'
                                    : ' 15 $ / einstellen (ohne Lieferung).'
                        }
                        <br />
                        <br />
                        {stateLanguage === "en"
                            ? 'Fill in and send the form for ordering AR-sets:'
                            : stateLanguage === "es"
                                ? 'Complete y envíe el formulario para solicitar AR-colocar:'
                                : stateLanguage === "ua"
                                    ? 'Заповніть та відправте форму замовлення AR-наборів:'
                                    : 'Füllen Sie das Formular zur Bestellung von AR-einstellen aus und senden Sie es ab:'
                        }
                    </div>

                    <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>

                        {stateLanguage === "en"
                            ? <label htmlFor="numberArea" className="form-label mt-4">Number of delivery AR-sets</label>
                            : stateLanguage === "es"
                                ? <label htmlFor="numberArea" className="form-label mt-4">Número de AR-colocar de entrega</label>
                                : stateLanguage === "ua"
                                    ? <label htmlFor="numberArea" className="form-label mt-4">Кількість AR-наборів для доставки</label>
                                    : <label htmlFor="numberArea" className="form-label mt-4">Anzahl der gelieferten AR-einstellen</label>}
                        <input
                            type="number"
                            className={this.state.isNumberOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="numberArea"  aria-describedby="emailHelp" placeholder={numberplaceholder}
                            name="number"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isNumberOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                        {stateLanguage && languages[stateLanguage].emailText}

                        <input
                            type="email"
                            /*id="emailField" */
                            className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={emailplaceholder}
                            name="mailfrom"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isEmailOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                        {stateLanguage && languages[stateLanguage].alertText}
                        {stateLanguage && languages[stateLanguage].nameText}
                        <input
                            type="text"
                            className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="exampleInputEmail2" aria-describedby="emailHelp"  placeholder={nameplaceholder}
                            name="username"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isNameOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}


                        {stateLanguage === "en"
                            ? <label htmlFor="adressArea" className="form-label mt-4">Delivery address</label>
                            : stateLanguage === "es"
                                ? <label htmlFor="adressArea" className="form-label mt-4">Dirección de entrega</label>
                                : stateLanguage === "ua"
                                    ? <label htmlFor="adressArea" className="form-label mt-4">Адреса доставки</label>
                                    : <label htmlFor="adressArea" className="form-label mt-4">Dirección de entrega</label>}
                        <input
                            type="text"
                            className={this.state.isAdressOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="adressArea" aria-describedby="emailHelp"  placeholder={adressplaceholder}  name="adress"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isAdressOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                        {stateLanguage && languages[stateLanguage].descriptionText}

                        <textarea id="exampleTextarea" rows="3"  name="message"  className={"form-control is-valid"}
                        // onChange={() => { this.checkFields() }}
                        />
                        {/* {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}    */}
                        <div className={"form-check "} style={{ paddingTop: '3%' }}>
                            <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse"
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />

                            {stateLanguage && languages[stateLanguage].privacyText}

                        </div>
                    </div>


                    {  this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                            <button type="submit" className="btn btn-outline-success" onClick={() =>this.sendMessege} >
                                {stateLanguage && languages[stateLanguage].buttonText}
                            </button>
                            : <button type="submit" className="btn btn-outline-success" disabled>
                                {stateLanguage && languages[stateLanguage].buttonText}
                            </button>}
                </form>;
        }
        else {
            content =
                <form id="mailform" className="col-10 justify-content-center cont1">

                    <div className="form-group row description_text" style={{ paddingLeft: '2%', paddingTop: '5%', marginBottom: '0%' }}>
                        {stateLanguage === "en"
                            ? <p className="col-form-label">Fill out and send the form to order AR-sets:</p>
                            : stateLanguage === "es"
                                ? <p className="col-form-label">Complete y envíe el formulario para solicitar sets AR:</p>
                                : stateLanguage === "ua"
                                    ? <p className="col-form-label"> До кожного AR-набору входять:</p>
                                    : <p className="col-form-label">Füllen Sie das Formular aus und senden Sie es ab, um AR-sets zu bestellen:</p>}
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '1. "Happy Birthday" design'
                                        : stateLanguage === "es"
                                            ? '1. Diseño de "feliz cumpleaños"'
                                            : stateLanguage === "ua"
                                                ? '1. Дизайн "Happy Birthday"'
                                                : '1. "Alles Gute zum Geburtstag" Design'
                                    }
                                </b>
                                <img width="100%" height="100%" src={hb1} alt="Happy Birthday picture" />
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 30 штук'
                                                    : 'Kartonglas (250 ml) - 30 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                laminated cardboard plate
                                                <br />
                                                (diameter 23 cm) - 30 pieces
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    placa de cartón laminado
                                                    <br />
                                                    (diámetro 23 cm) - 30 piezas
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        тарілка ламінована картонна
                                                        <br />
                                                        (діаметр 23 см) - 30 штук</div>
                                                    : <div>
                                                        laminierter Kartonteller
                                                        <br />
                                                        (Durchmesser 23 cm) - 30 Stück</div>
                                        }
                                    </li>
                                    <br />
                                </ul>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '2. "Bear" design'
                                        : stateLanguage === "es"
                                            ? '2. Diseño de "Оso"'
                                            : stateLanguage === "ua"
                                                ? '2. Дизайн «Ведмедик»'
                                                : '2. "Bär"-Design'
                                    }
                                </b>
                                <img width="100%" height="100%" src={mishka1} alt="текст" style={{ marginLeft: "5px" }} />
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 20 штук'
                                                    : 'Kartonglas (250 ml) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                laminated cardboard plate
                                                <br />
                                                (diameter 23 cm) - 20 pieces
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    placa de cartón laminado
                                                    <br />
                                                    (diámetro 23 cm) - 20 piezas
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        тарілка ламінована картонна
                                                        <br />
                                                        (діаметр 23 см) - 20 штук</div>
                                                    : <div>
                                                        laminierter Kartonteller
                                                        <br />
                                                        (Durchmesser 23 cm) - 20 Stück</div>
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                cardboard cap
                                                <br />
                                                (height 18 cm) - 20 pieces'
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    tapa de cartón
                                                    <br />
                                                    (altura 18 cm) - 20 piezas'
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        картонний ковпак
                                                        <br />
                                                        (висота 18 см) - 20 штук
                                                    </div>
                                                    : <div>
                                                        Pappkappe
                                                        <br />
                                                        (Höhe 18 cm) - 20 Stück
                                                    </div>
                                        }
                                    </li>
                                </ul>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <b className="b-size">
                                    {stateLanguage === "en"
                                        ? '3. Design "Marmalade"'
                                        : stateLanguage === "es"
                                            ? '3. Diseño "Mermelada"'
                                            : stateLanguage === "ua"
                                                ? '3. Дизайн "Мармелад"'
                                                : '3. Design "Marmelade"'
                                    }
                                </b>
                                <img width="100%" height="100%" src={marmelad1} alt="текст" />
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 20 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 20 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 20 штук'
                                                    : 'Kartonglas (250 ml) - 20 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                laminated cardboard plate
                                                <br />
                                                (diameter 23 cm) -230 pieces
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    placa de cartón laminado
                                                    <br />
                                                    (diámetro 23 cm) - 20 piezas
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        тарілка ламінована картонна
                                                        <br />
                                                        (діаметр 23 см) - 20 штук</div>
                                                    : <div>
                                                        laminierter Kartonteller
                                                        <br />
                                                        (Durchmesser 23 cm) - 20 Stück</div>
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                cardboard cap
                                                <br />
                                                (height 18 cm) - 20 pieces'
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    tapa de cartón
                                                    <br />
                                                    (altura 18 cm) - 20 piezas'
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        картонний ковпак
                                                        <br />
                                                        (висота 18 см) - 20 штук
                                                    </div>
                                                    : <div>
                                                        Pappkappe
                                                        <br />
                                                        (Höhe 18 cm) - 20 Stück
                                                    </div>
                                        }
                                    </li>
                                </ul>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <b className="b-size">
                                    {stateLanguage === "EN"
                                        ? '4. "Sakura" design'
                                        : stateLanguage === "ES"
                                            ? '4. Diseño "Sakura"'
                                            : stateLanguage === "UA"
                                                ? '4. Дизайн "Сакура"'
                                                : '4. "Sakura" Design'
                                    }
                                </b>
                                <img width="100%" height="100%" src={sakura1} alt="текст" />
                                <ul className="ul-style" >
                                    <li >
                                        {stateLanguage === "en"
                                            ? 'cardboard glass (250 ml) - 30 pieces'
                                            : stateLanguage === "es"
                                                ? 'vaso de cartón (250 ml) - 30 piezas'
                                                : stateLanguage === "ua"
                                                    ? 'стакан картонний (250 мл) - 30 штук'
                                                    : 'Kartonglas (250 ml) - 30 Stück'
                                        }
                                    </li>
                                    <li >
                                        {stateLanguage === "en"
                                            ? <div>
                                                laminated cardboard plate
                                                <br />
                                                (diameter 23 cm) - 30 pieces
                                            </div>
                                            : stateLanguage === "es"
                                                ? <div>
                                                    placa de cartón laminado
                                                    <br />
                                                    (diámetro 23 cm) - 30 piezas
                                                </div>
                                                : stateLanguage === "ua"
                                                    ? <div>
                                                        тарілка ламінована картонна
                                                        <br />
                                                        (діаметр 23 см) - 30 штук</div>
                                                    : <div>
                                                        laminierter Kartonteller
                                                        <br />
                                                        (Durchmesser 23 cm) - 30 Stück</div>
                                        }
                                    </li>
                                    <br />
                                </ul>

                            </div>
                        </div>
                        {stateLanguage === "en"
                            ? <div>A total of 180 items in the set
                                <br /> (box volume 0.018 cubic meters,
                                <br /> box weight 2.59 kg).
                            </div>
                            : stateLanguage === "es"
                                ? 'Un total de 180 artículos en el set (volumen de la caja 0,018 metros cúbicos, peso de la caja 2.59 kg).'
                                : stateLanguage === "ua"
                                    ? "Всього в наборі 180 предметів (об'єм коробки 0,018 куб. м, вага коробки 2.59 кг)."
                                    : 'Insgesamt 180 Artikel im set (Boxvolumen 0,018 Kubikmeter, Boxgewicht 2.59 kg).'
                        }
                        <br />
                        <b className="b-size">
                            {stateLanguage === "en"
                                ? 'Price:'
                                : stateLanguage === "es"
                                    ? 'Precio:'
                                    : stateLanguage === "ua"
                                        ? 'Ціна:'
                                        : 'Preis:'
                            }
                        </b>
                        {stateLanguage === "en"
                            ? ' 15 $ / set (without delivery).'
                            : stateLanguage === "es"
                                ? ' 15 $ / colocar (sin entrega).'
                                : stateLanguage === "ua"
                                    ? ' 15 $ / набір (без доставки).'
                                    : ' 15 $ / einstellen (ohne Lieferung).'
                        }
                        <br />
                        <br />
                        {stateLanguage === "en"
                            ? 'Fill in and send the form for ordering AR-sets:'
                            : stateLanguage === "es"
                                ? 'Complete y envíe el formulario para solicitar AR-colocar:'
                                : stateLanguage === "ua"
                                    ? 'Заповніть та відправте форму замовлення AR-наборів:'
                                    : 'Füllen Sie das Formular zur Bestellung von AR-einstellen aus und senden Sie es ab:'
                        }
                    </div>

                    <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>

                        {stateLanguage === "en"
                            ? <label htmlFor="numberArea" className="form-label mt-4">Number of delivery AR-sets</label>
                            : stateLanguage === "es"
                                ? <label htmlFor="numberArea" className="form-label mt-4">Número de AR-colocar de entrega</label>
                                : stateLanguage === "ua"
                                    ? <label htmlFor="numberArea" className="form-label mt-4">Кількість AR-наборів для доставки</label>
                                    : <label htmlFor="numberArea" className="form-label mt-4">Anzahl der gelieferten AR-einstellen</label>}
                        <input
                            type="number"
                            className={this.state.isNumberOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="numberArea"
                            aria-describedby="emailHelp"
                            placeholder={numberplaceholder}
                            name="number"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isNumberOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                        {stateLanguage && languages[stateLanguage].emailText}



                        <input
                            type="email"
                            /*id="emailField"*/
                            className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={emailplaceholder}
                            name="mailfrom"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isEmailOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                        {stateLanguage && languages[stateLanguage].alertText}
                        {stateLanguage && languages[stateLanguage].nameText}
                        <input
                            type="text"
                            className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="exampleInputEmail2"
                            aria-describedby="emailHelp"
                            placeholder={nameplaceholder}
                            name="username"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isNameOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}


                        {stateLanguage === "en"
                            ? <label htmlFor="adressArea" className="form-label mt-4">Delivery address</label>
                            : stateLanguage === "es"
                                ? <label htmlFor="adressArea" className="form-label mt-4">Dirección de entrega</label>
                                : stateLanguage === "ua"
                                    ? <label htmlFor="adressArea" className="form-label mt-4">Адреса доставки</label>
                                    : <label htmlFor="adressArea" className="form-label mt-4">Dirección de entrega</label>}
                        <input
                            type="text"
                            className={this.state.isAdressOk ? "form-control is-valid" : "form-control is-invalid"}
                            id="adressArea"
                            aria-describedby="emailHelp"
                            placeholder={adressplaceholder}
                            name="adress"
                            onChange={() => { this.checkFields() }}
                        />
                        {!this.state.isAdressOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}





                        {stateLanguage && languages[stateLanguage].descriptionText}

                        <textarea id="exampleTextarea" rows="3"
                            name="message"
                            className={"form-control is-valid"}
                        // onChange={() => { this.checkFields() }}
                        />
                        {/* {!this.state.isMessageOk ? <div className="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>} */}
                        <div className="form-check" style={{ paddingTop: '3%' }}>
                            <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse"
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />

                            {stateLanguage && languages[stateLanguage].privacyText}

                        </div>
                    </div>


                    {
                        this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                            <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege} > {/*() =>*/}
                                {stateLanguage && languages[stateLanguage].buttonText}
                            </button>
                            : <button type="submit" className="btn btn-outline-success" disabled>
                                {stateLanguage && languages[stateLanguage].buttonText}
                            </button>}


                    {/* <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege} >Submit</button> :
                     <button type="submit" className="btn btn-outline-success " disabled >Submit</button> */}
                    {/* {stateLanguage === "EN"
            ? <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege} >Submit</button>
            : stateLanguage === "ES"
                ? <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege}>Entregar</button>
                : stateLanguage === "UA"
                    ? <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege}>Відправити</button>
                    : <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege}>Submit</button>} */}

                </form>
                ;
        }
        //#endregion
        return (
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col cont2"></div>
                        <div className="col-1 cont1"></div>
                        {content}
                        <div className="col cont2"></div>
                        <div className="col-1 cont1"></div>
                    </div>

                </div>
            </main>
        );
    }
}
